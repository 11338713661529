import React from 'react';
import PasswordReset from 'gatsby-theme-q3/src/pages/password-reset';
import FormContainer from '../../components/FormContainer';
import HydrationFix from '../../components/HydrationFix';

const CustomPasswordReset = (props) => (
  <HydrationFix>
    <FormContainer>
      <PasswordReset {...props} />
    </FormContainer>
  </HydrationFix>
);

export default CustomPasswordReset;
